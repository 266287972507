import * as React from 'react'
import MuxPlayer from '@mux/mux-player-react'
import { MuxVideoWrapper } from './styled'
const { useState, useEffect } = React

interface MuxVideoProps {
  video: Mux.video
}

export const MuxVideo: React.FC<MuxVideoProps> = ({ video }) => {
  return (
    <MuxVideoWrapper aspectRatio={video.data.aspect_ratio}>
      <MuxPlayer
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          // @ts-ignore
          '--seek-backward-button': 'none',
          // @ts-ignore
          '--seek-forward-button': 'none',
          // @ts-ignore
          '--volume-range': 'none',
          // @ts-ignore
          '--media-mute-button': 'none',
          // @ts-ignore
          '--top-captions-button': 'none',
          // @ts-ignore
          '--time-range': 'none',
          // @ts-ignore
          '--time-display': 'none',
          // @ts-ignore
          '--duration-display': 'none',
          // @ts-ignore
          '--playback-rate-button': 'none',
          // @ts-ignore
         '--pip-button': 'none',

        }}
        streamType="on-demand"
        playbackId={video.playbackId}
        thumbnailTime={video.thumbTime}
        primaryColor="#f7a1d0"
        secondaryColor="#000"
        metadata={{
          video_id: video.assetId,
        }}
      />
    </MuxVideoWrapper>
  )
}
