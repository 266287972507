import { HomePage } from '@/types/sanity'
import { ContentBlock } from '@/components/ContentBlock'
import * as React from 'react'
import { definitely } from '@/utils'
import { HomeViewWrapper } from './styles'

interface HomeViewProps {
  homePage: HomePage
}

export const HomeView: React.FC<HomeViewProps> = (props) => {
  const { seo, contentBlocks, title } = props.homePage

  return (
    <HomeViewWrapper>
      {definitely(contentBlocks).map((c) => {
        return <ContentBlock key={c._key || 'some-key'} content={c} />
      })}
    </HomeViewWrapper>
  )
}
